import { Button, Modal, ModalProps } from 'semantic-ui-react';
import { Action } from 'lowcode-shared-code';

export function ConfirmationPromptModal(
    props: ModalProps & {
        action: Action;
        onContinue(): void;
    }
) {
    const { action, onContinue } = props;

    function onCancel() {
        props.onClose?.(null!, null!);
    }

    return (
        <Modal size="tiny" {...props}>
            <Modal.Header className="bg-primary text-primary">
                {action.name}
            </Modal.Header>
            <Modal.Content>{action.confirmation_prompt}</Modal.Content>
            <Modal.Actions className="bg-neutral">
                <Button onClick={onContinue}>Continuar</Button>
                <Button onClick={onCancel}>Cancelar</Button>
            </Modal.Actions>
        </Modal>
    );
}
