import {
    byId,
    DashboardFlow,
    Employee,
    EventOverview,
    EventWorkload,
    Ref,
} from 'lowcode-shared-code';
import _ from 'lodash';

export class Stats {
    activeTasks: number;
    terminatedTasks: number;

    static ZERO = new Stats({
        activeTasks: 0,
        terminatedTasks: 0,
    });

    constructor(params: { activeTasks: number; terminatedTasks: number }) {
        this.activeTasks = params.activeTasks;
        this.terminatedTasks = params.terminatedTasks;
    }

    get totalTasks(): number {
        return this.activeTasks + this.terminatedTasks;
    }

    add(other: Stats): Stats {
        return new Stats({
            activeTasks: this.activeTasks + other.activeTasks,
            terminatedTasks: this.terminatedTasks + other.terminatedTasks,
        });
    }
}

export function getEmployeeStats(
    dashboardFlow: DashboardFlow,
    employee: Employee
): Stats {
    return dashboardFlow.events.reduce(
        (stats, eventWorkload) =>
            stats.add(
                new Stats({
                    activeTasks:
                        eventWorkload.active_employees.find(
                            e => e.profile.user.id === employee.profile.user.id
                        )?.tasks ?? 0,
                    terminatedTasks:
                        eventWorkload.terminated_employees.find(
                            e => e.profile.user.id === employee.profile.user.id
                        )?.tasks ?? 0,
                })
            ),
        Stats.ZERO
    );
}

export function getEventStats(
    dashboardFlow: DashboardFlow,
    event: Ref<EventOverview>
): Stats {
    const eventWorkload = dashboardFlow.events.find(byId(event.id))!;

    return new Stats({
        activeTasks: _.sum(_.map(eventWorkload.active_employees, 'tasks')),
        terminatedTasks: _.sum(
            _.map(eventWorkload.terminated_employees, 'tasks')
        ),
    });
}
