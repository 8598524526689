import {
    Await,
    useGetFlowsQuery,
    useGetWhoAmIQuery,
} from 'lowcode-shared-code';
import { Dropdown } from 'semantic-ui-react';
import React from 'react';
import { useDashboard } from './dashboard';

export function HeaderSection() {
    const getWhoAmIQuery = useGetWhoAmIQuery();

    return (
        <section className="flex flex-row mb-8">
            <div className="flex-1">
                <h2 className="text-4xl">Inicio</h2>
                <p>
                    Gusto en verte nuevamente{' '}
                    <Await query={getWhoAmIQuery}>
                        {whoAmI => <span>{whoAmI.user.first_name}</span>}
                    </Await>
                    .
                </p>
            </div>

            <div>
                <PostApplicationButton />
            </div>
        </section>
    );
}

export function PostApplicationButton() {
    const getFlowsQuery = useGetFlowsQuery();
    const dashboard = useDashboard();

    return (
        <Dropdown
            floating
            trigger={
                <button className="btn-primary text-xl px-14">
                    Iniciar solicitud
                </button>
            }
            icon={null}
            loading={getFlowsQuery.isLoading}
        >
            <Dropdown.Menu className="w-full">
                {getFlowsQuery.data?.map(flow => (
                    <Dropdown.Item
                        key={flow.id}
                        onClick={() => dashboard.initialFormModal.open(flow)}
                    >
                        {flow.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}
