import {
    Action,
    FormDetail,
    LowcodeFormData,
    TabOverview,
    validateForm,
} from 'lowcode-shared-code';
import _ from 'lodash';

export interface ActionValidationResult {
    action: Action;
    validTabs: Array<TabOverview['id']>;
    isValid: boolean;
}

export function validateAction(
    action: Action,
    application_data: LowcodeFormData
): ActionValidationResult {
    const validTabs = _.map(
        action.tabs.filter(
            tab =>
                !tab.form ||
                validateForm(tab.form as FormDetail, application_data)
        ),
        'id'
    );
    const isValid = validTabs.length === action.tabs.length;

    return {
        action,
        validTabs,
        isValid,
    };
}
