import { List } from 'semantic-ui-react';
import React, { ReactElement } from 'react';
import { Application, byId, FlowDetail } from 'lowcode-shared-code';

export function ApplicationState(props: {
    application: Application;
    flow: FlowDetail;
}) {
    return (
        <EventsCellList
            events={props.application.state.map(({ event }) => ({
                name:
                    props.flow.events.find(byId(event))?.name ?? '(Eliminado)',
            }))}
        />
    );
}

interface INamedEvent {
    name: string;
}

export function EventsCellList(props: { events: INamedEvent[] }) {
    return (
        <CellList
            items={props.events.map(event => event.name)}
            emptyLabel="Terminado"
        />
    );
}

export function CellList(props: {
    items: (ReactElement | string)[];
    emptyLabel: ReactElement | string;
}) {
    const { items, emptyLabel } = props;

    if (items.length === 0) {
        return <span className="text-gray font-normal">{emptyLabel}</span>;
    }

    if (items.length === 1) {
        return <span>{items[0]}</span>;
    }

    return (
        <List bulleted>
            {items.map(item => (
                <List.Item>{item}</List.Item>
            ))}
        </List>
    );
}
