import React, { ReactElement } from 'react';
import { FiltersDropdown } from './FiltersDropdown';
import {
    Application,
    Await,
    endpoints,
    Paginated,
    usePaginatedApplicationsQuery,
    useQueryParams,
} from 'lowcode-shared-code';
import { DashboardProvider } from './dashboard';
import { SideBar } from '../../ui-components/SideBar';
import { Screen } from '../Screen';
import _ from 'lodash';
import { DashboardTable } from './DashboardTable';
import { PostApplicationButton } from './HeaderSection';
import { Popup } from 'semantic-ui-react';
import { useLocale } from '../../locales/localeContext';
import { QueryObserverResult } from 'react-query';
import { PaginatedItems } from '../../ui-components/PaginatedItems';

export function AllApplicationsScreen() {
    const locale = useLocale();

    return (
        <Screen className="flex flex-row">
            <DashboardProvider>
                <SideBar />
                <main className="flex-1 bg-neutral px-10">
                    <section className="mt-8">
                        <div className="flex flex-row items-center space-x-4 mb-2">
                            <h3 className="text-2xl mb-0">
                                Listado de solicitudes
                            </h3>
                            <Popup
                                trigger={
                                    <div className="flex-1">
                                        <input
                                            type="text"
                                            placeholder="Buscar solicitud..."
                                            disabled
                                            className="flex-1 h-10 px-6 font-bold bg-gray_400 mr-8 input"
                                        />
                                    </div>
                                }
                                content={locale.notImplementedYet}
                            />
                            <Popup
                                trigger={
                                    <div>
                                        <FiltersDropdown disabled />
                                    </div>
                                }
                                content={locale.notImplementedYet}
                            />
                            <PostApplicationButton />
                        </div>

                        <PaginatedItems<Application>
                            useQuery={usePaginatedApplicationsQuery}
                            page_size={10}
                            fallback={<p>No hay solicitudes.</p>}
                        >
                            {applications => (
                                <DashboardTable applications={applications} />
                            )}
                        </PaginatedItems>
                    </section>
                </main>
            </DashboardProvider>
        </Screen>
    );
}
