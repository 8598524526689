import {
    endpoints,
    InboxTask,
    IPaginationQueryParams,
    Paginated,
    useGetQuery,
} from 'lowcode-shared-code';
import { UseQueryResult } from 'react-query';

export function useInboxQuery(
    params: IPaginationQueryParams
): UseQueryResult<Paginated<InboxTask>> {
    return useGetQuery(endpoints.getInbox, { terminated: false, ...params });
}

export function useLedgerQuery(
    params: IPaginationQueryParams
): UseQueryResult<Paginated<InboxTask>> {
    return useGetQuery(endpoints.getInbox, { terminated: true, ...params });
}
