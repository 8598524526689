import { PasswordField, SelectField } from 'lowcode-shared-code';
import { useFormContext } from 'react-hook-form';
import React from 'react';

export function StatesField() {
    return (
        <SelectField
            name="states"
            options={[]}
            label="Estados"
            blankOption="Todos los estados"
            optional
        />
    );
}

export function StagesField() {
    return (
        <SelectField
            name="stages"
            options={[]}
            label="Etapas"
            blankOption="Todas las etapas"
            optional
        />
    );
}

export function CurrentPasswordField() {
    return <PasswordField name="password" label="Contraseña actual" />;
}

export function NewPasswordField() {
    return <PasswordField name="new_password" label="Contraseña nueva" />;
}

export function RepeatPasswordField(props: {
    relatedName: string;
    label: string;
}) {
    const { watch } = useFormContext();
    const newPassword = watch(props.relatedName);

    return (
        <PasswordField
            name="repeat_password"
            label={props.label}
            validate={(value: string) => {
                if (value !== newPassword) {
                    return 'Las contraseñas no coinciden';
                }
                return true;
            }}
        />
    );
}
