import {
    Action,
    Await,
    EventOverview,
    icons,
    InteractivityContext,
    sorted,
    useEventDetailQuery,
} from 'lowcode-shared-code';
import { useLocale } from '../../locales/localeContext';
import { useContext } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import _ from 'lodash';

export function EventActions(props: { event: EventOverview }) {
    const { event } = props;
    const locale = useLocale();
    const { actionDisabledReason } = useContext(InteractivityContext);
    const eventDetailQuery = useEventDetailQuery(event);

    return (
        <Popup
            content={actionDisabledReason}
            disabled={!actionDisabledReason}
            position={'bottom left'}
            trigger={
                <div className="flex flex-col items-stretch space-y-2">
                    <Await query={eventDetailQuery}>
                        {event =>
                            _.isEmpty(event.actions)
                                ? locale.selectedXDoesNotHaveAnyYs(
                                      locale.eventSingular,
                                      locale.actionPlural
                                  )
                                : sorted(event.actions).map(action => (
                                      <ActionButton
                                          key={action.id}
                                          action={action}
                                      />
                                  ))
                        }
                    </Await>
                </div>
            }
        />
    );
}

function ActionButton(props: { action: Action }) {
    const { action } = props;
    const { actionDisabledReason, onClickAction } =
        useContext(InteractivityContext);

    const Icon = icons[action.icon ?? 'Next'];
    const color = action.color ?? 'black';

    return (
        <Button
            onClick={() => onClickAction?.(action)}
            disabled={actionDisabledReason != null}
            className="h-12 flex flex-row items-center px-4 space-x-2"
            style={{
                border: '2px solid #aaa',
                color,
            }}
        >
            {Icon && <Icon />}
            <div className="flex-1 text-center">{action.name}</div>
        </Button>
    );
}
