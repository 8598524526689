import { Screen } from '../Screen';
import { FormContainer, FormPreview, SubmitButton } from 'lowcode-shared-code';
import {
    CurrentPasswordField,
    NewPasswordField,
    RepeatPasswordField,
} from '../../custom-fields/fields';
import { useChangePasswordMutation } from '../../common/mutations';
import { routes } from '../../common/routes';
import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { useState } from 'react';
import { isFetchError } from '@tdc-cl/async-stuff';

export function AccountSettingsScreen() {
    const changePasswordMutation = useChangePasswordMutation();
    const [done, setDone] = useState(false);
    const error = changePasswordMutation.error;

    async function changePassword(formValues: {
        password: string;
        new_password: string;
    }) {
        try {
            await changePasswordMutation.mutateAsync(formValues);
            setDone(true);
        } catch {}
    }

    return (
        <Screen className="bg-neutral flex flex-col items-center justify-center">
            <div className="max-w-md">
                <h1>Configuración de la cuenta</h1>
                <h2>Cambiar contraseña</h2>
                {isFetchError(error) &&
                    (error.response.status === 403 ? (
                        <Message negative>
                            Tu contraseña actual no es correcta.
                        </Message>
                    ) : (
                        <Message negative>
                            Hubo un problema y no pudimos cambiar tu contraseña.
                        </Message>
                    ))}
                {done ? (
                    <Message positive>
                        Tu contraseña ha sido cambiada con éxito.
                    </Message>
                ) : (
                    <FormPreview
                        submitLabel="Cambiar contraseña"
                        submittingLabel="Cambiando contraseña"
                        onSubmit={changePassword}
                        onInvalid="disable"
                        disableReason="Completa el formulario para poder cambiar tu contraseña"
                    >
                        <FormContainer>
                            <CurrentPasswordField />
                            <div className="h-8" />
                            <NewPasswordField />
                            <RepeatPasswordField
                                relatedName="new_password"
                                label="Repetir contraseña nueva"
                            />
                            <SubmitButton />
                        </FormContainer>
                    </FormPreview>
                )}
                <Link to={routes.dashboard.url({})}>Volver al dashboard</Link>
            </div>
        </Screen>
    );
}
