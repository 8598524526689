import dashboardCells from './dashboardCells.png';
import { Screen } from '../Screen';

export function CellsTutorialScreen() {
    return (
        <Screen public>
            <main>
                <h1>Tutorial - Agregar celdas</h1>

                <h2>👨‍💻 Acción requerida</h2>
                <p className="mb-4">
                    Para agregar celdas, toma tu editor de código favorito y
                    modifica el archivo
                    <br />
                    <code>
                        packages/interpreter/src/screens/dashboard/DashboardRow.tsx
                    </code>
                    .
                </p>
                <img src={dashboardCells} alt="" />

                <p className="mb-16">
                    Ahí podrás agregar nuevas etiquetas <code>&lt;td&gt;</code>{' '}
                    para agregar más celdas. También puedes borrar la etiqueta{' '}
                    <code>&lt;td&gt;</code> de la celda que te llevó a este
                    tutorial.
                </p>

                <p>
                    El front debería recargarse automáticamente. Si esto no
                    ocurre intenta recargando el sitio.
                </p>
            </main>
        </Screen>
    );
}
