import { useProject } from '../common/ProjectProvider';
import {
    authStorage,
    Await,
    Route,
    useGetMyRolesQuery,
    useGetWhoAmIQuery,
} from 'lowcode-shared-code';
import { Link, useRouteMatch } from 'react-router-dom';
import { Icon, IconProps, Loader } from 'semantic-ui-react';
import React, { PropsWithChildren } from 'react';
import _ from 'lodash';
import { routes } from '../common/routes';
import classNames from 'classnames';
import { isSuperAdmin } from '../utils/auth';
import { IndicatorsSection } from './IndicatorsSection';
import { useInboxQuery } from '../screens/inbox/queries';

function Tab(
    props: PropsWithChildren<{
        route: Route<{}>;
        icon?: IconProps['name'];
    }>
) {
    const match = useRouteMatch();
    const isActive = props.route.path === match.path;

    return (
        <li className={classNames({ active: isActive })}>
            <Link
                to={props.route.url({})}
                className={classNames(
                    'pl-4 w-full block py-2 flex items-center',
                    isActive
                        ? 'bg-primary text-primary hover:text-primary'
                        : 'bg-secondary text-secondary hover:text-secondary'
                )}
            >
                <span>
                    <Icon
                        name={props.icon}
                        className={isActive ? 'text-primary' : 'text-secondary'}
                    />
                </span>
                <span className="ml-3">{props.children}</span>
            </Link>
        </li>
    );
}

function AllApplicationsTab() {
    return (
        <Tab route={routes.dashboard} icon="database">
            Todas las solicitudes
        </Tab>
    );
}

function InboxTab() {
    const getInboxQuery = useInboxQuery({ page: 1, page_size: 10 });

    return (
        <Tab route={routes.inbox} icon="inbox">
            Bandeja de entrada{' '}
            <Await query={getInboxQuery} fallback={<Loader size="mini" />}>
                {inbox => <Badge count={inbox.count} />}
            </Await>
        </Tab>
    );
}

function Badge(props: { count: number }) {
    if (props.count === 0) {
        return null;
    }

    return <span className="mx-1">({props.count})</span>;
}

function LedgerTab() {
    return (
        <Tab route={routes.ledger} icon="history">
            Tareas terminadas
        </Tab>
    );
}

function SupervisorTab() {
    return (
        <Tab route={routes.supervisor} icon="eye">
            Supervisor
        </Tab>
    );
}

function UserManagerTab() {
    return (
        <Tab route={routes.userManager} icon="user">
            Gestión de usuarios
        </Tab>
    );
}

export function SideBar() {
    const project = useProject();
    const getWhoAmIQuery = useGetWhoAmIQuery();
    const getMyRolesQuery = useGetMyRolesQuery();

    return (
        <nav className="bg-secondary shadow-lg p-4 pr-0" id={'sidebar'}>
            <img src={project.logo} alt="" className="h-12 mr-10 ml-6" />
            <section className="mb-8">
                <h3 className="sidebar-title text-sm font-light text-gray pl-4">
                    Gestión
                </h3>
                <div>
                    <ul>
                        <Await query={getWhoAmIQuery}>
                            {whoAmI =>
                                isSuperAdmin(whoAmI.user) ? (
                                    <React.Fragment>
                                        <AllApplicationsTab />
                                        <SupervisorTab />
                                        <UserManagerTab />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <InboxTab />
                                        <LedgerTab />
                                        <AllApplicationsTab />
                                        <Await query={getMyRolesQuery}>
                                            {roles => (
                                                <React.Fragment>
                                                    {roles.some(role =>
                                                        /admin/i.test(role.name)
                                                    ) && <SupervisorTab />}
                                                </React.Fragment>
                                            )}
                                        </Await>
                                    </React.Fragment>
                                )
                            }
                        </Await>
                    </ul>
                </div>
            </section>

            <IndicatorsSection />

            <section className="mb-8 p-4">
                <h3 className="sidebar-title text-sm font-light text-gray">
                    Usuario
                </h3>
                <Await query={getWhoAmIQuery}>
                    {whoAmI => (
                        <React.Fragment>
                            <p className={'mb-0'}>
                                {whoAmI.user.first_name} {whoAmI.user.last_name}
                            </p>
                            {isSuperAdmin(whoAmI.user) ? (
                                <p>Administrador</p>
                            ) : (
                                <RoleList />
                            )}
                        </React.Fragment>
                    )}
                </Await>
                <div>
                    <Link to={routes.accountSettings.url({})}>
                        Configuración de la cuenta
                    </Link>
                </div>
                <LogoutButton />
            </section>
        </nav>
    );
}

function RoleList() {
    const getMyRolesQuery = useGetMyRolesQuery();

    return (
        <Await query={getMyRolesQuery}>
            {roles => (
                <React.Fragment>
                    {roles.map(role => (
                        <p key={role.id} className="text-gray mb-0">
                            {_.capitalize(role.name)}
                        </p>
                    ))}
                </React.Fragment>
            )}
        </Await>
    );
}

function LogoutButton() {
    function logout() {
        authStorage.clear();
    }

    return (
        <button className="btn-primary bg-primary mt-4" onClick={logout}>
            Cerrar sesión
        </button>
    );
}
