import { routes } from '../../common/routes';
import { Link } from 'react-router-dom';
import React from 'react';

export function ReturnToLoginLink() {
    return (
        <Link to={routes.login.url({})}>
            Volver a la pantalla de inicio de sesión
        </Link>
    );
}
