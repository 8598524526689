import React from 'react';
import { LoginForm } from 'lowcode-shared-code';
import { useProject } from '../../common/ProjectProvider';
import { Screen } from '../Screen';

export function LoginScreen() {
    const project = useProject();

    return (
        <Screen public className="flex flex-row">
            <div className="w-2/5 bg-gray-200 flex flex-col items-center justify-center">
                <div className={'w-1/2'}>
                    <img src={project.logo} alt="" className="h-12" />
                    <h1 className={'text-3xl mt-20 font-bold'}>Ingresar</h1>
                    <p className={'text-normal font-semibold mb-16'}>
                        Para ingresar complete los siguientes datos.
                    </p>
                    <LoginForm />
                </div>
            </div>
            <div className="w-3/5 bg-primary text-primary flex flex-col items-center justify-center">
                <div className={'w-9/12 mx-auto'}>
                    <h1 className={'text-5xl font-bold leading-tight'}>
                        {project.name}
                    </h1>
                    <p className={'font-semibold mt-6'}>{project.subtitle}</p>
                </div>
            </div>
        </Screen>
    );
}
