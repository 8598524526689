import React, { PropsWithChildren, useState } from 'react';
import { StructError } from 'superstruct';
import {
    RequestErrorDetail,
    useRequestErrorHandler,
} from '@tdc-cl/async-stuff';
import { Render, RenderersProvider } from '@tdc-cl/react-object-renderer';
import { RenderStructError } from '@tdc-cl/struct-error-renderer';
import { Icon, Message, Modal } from 'semantic-ui-react';

export function StructErrorBoundary(props: PropsWithChildren<{}>) {
    const [open, setOpen] = useState(false);
    const [detail, setDetail] =
        useState<RequestErrorDetail<StructError> | null>(null);

    useRequestErrorHandler({
        onStructError(detail) {
            setDetail(detail);
            setOpen(true);
        },
    });

    return (
        <React.Fragment>
            {props.children}
            <Modal open={open} onClose={() => setOpen(false)}>
                <Modal.Header>Error de tipos</Modal.Header>
                {detail && (
                    <Modal.Content scrolling>
                        <p>
                            La respuesta entregada por el backend no posee el
                            mismo tipo que espera el frontend.
                        </p>
                        <h3>
                            <code>
                                {detail.request.method} {detail.request.url}
                            </code>
                        </h3>
                        <h4>
                            <code>{detail.endpoint.name}</code>
                        </h4>
                        <Message negative>{detail.error.message}</Message>
                        <p>
                            <Icon name="lightbulb" color="yellow" /> Puedes
                            poner el mouse sobre cualquier{' '}
                            <span
                                style={{
                                    textDecorationStyle: 'wavy',
                                    textDecorationColor: 'red',
                                    textDecorationLine: 'underline',
                                }}
                            >
                                error
                            </span>{' '}
                            para ver más detalles.
                        </p>
                        <RenderersProvider renderers={{ RenderStructError }}>
                            <Render value={detail.error} />
                        </RenderersProvider>
                    </Modal.Content>
                )}
            </Modal>
        </React.Fragment>
    );
}
