import {
    EventOverview,
    Ref,
    TabOverview,
    useGetWhoAmIQuery,
} from 'lowcode-shared-code';
import { useApplication } from '../../utils/applicationContext';
import _ from 'lodash';
import { useLocale } from '../../locales/localeContext';
import { ReactElement } from 'react';
import { UserSummary } from './UserSummary';

export function useEventStatus(event: Ref<EventOverview>, tab?: TabOverview) {
    const locale = useLocale();
    const application = useApplication();
    const getWhoAmIQuery = useGetWhoAmIQuery();

    const isActiveEvent = _.map(application.state, 'event').includes(event.id);
    const assignedEmployee = application.state.find(
        s => s.event === event.id
    )?.employee;
    const isAssignedToAnotherEmployee =
        assignedEmployee &&
        getWhoAmIQuery.data &&
        assignedEmployee.profile.user.id !== getWhoAmIQuery.data.user.id;
    const isLoading = getWhoAmIQuery.isLoading;
    const disableReason = getDisableReason();

    function getDisableReason(): ReactElement | string | undefined {
        if (!isActiveEvent) {
            return locale.disabledBecauseInactiveEvent;
        } else if (tab?.readonly) {
            return 'En esta pestaña los datos son de solo lectura';
        } else if (isLoading) {
            return locale.loading;
        } else if (isAssignedToAnotherEmployee) {
            return (
                <span>
                    No puedes hacer cambios en este evento pues está asignado a
                    <br />
                    <UserSummary user={assignedEmployee!.profile.user} />
                </span>
            );
        }
    }

    return {
        assignedEmployee,
        disableReason,
        isActiveEvent,
        isAssignedToAnotherEmployee,
        isLoading,
    };
}
