import React from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { LoginScreen } from './screens/login/LoginScreen';
import { AllApplicationsScreen } from './screens/dashboard/AllApplicationsScreen';
import { tutorialRoutes } from './screens/tutorial/tutorialRoutes';
import { ProjectProvider } from './common/ProjectProvider';
import { QueryClientProvider } from 'react-query';
import { WorkflowScreen } from './screens/workflow/WorkflowScreen';
import { LocaleProvider } from './locales/localeContext';
import { routes } from './common/routes';
import { InboxScreen } from './screens/inbox/InboxScreen';
import { RequestPasswordResetScreen } from './screens/reset-password/RequestPasswordResetScreen';
import { CheckPasswordResetEmailScreen } from './screens/reset-password/CheckPasswordResetEmailScreen';
import { PasswordChangeSuccessScreen } from './screens/reset-password/PasswordChangeSuccessScreen';
import { ChangePasswordScreen } from './screens/reset-password/ChangePasswordScreen';
import { AccountSettingsScreen } from './screens/account-settings/AccountSettingsScreen';
import { StructErrorBoundary } from './utils/StructErrorBoundary';
import { SupervisorScreen } from './screens/supervisor/SupervisorScreen';
import { UserManagerScreen } from './screens/sign-up/UserManagerScreen';
import { MutationErrorHandler, queryClient } from 'lowcode-shared-code';

export default function App() {
    return (
        <QueryClientProvider client={queryClient} contextSharing>
            <LocaleProvider>
                <StructErrorBoundary>
                    <ProjectProvider>
                        <CurrentScreen />
                    </ProjectProvider>
                </StructErrorBoundary>
                <MutationErrorHandler />
            </LocaleProvider>
        </QueryClientProvider>
    );
}

function CurrentScreen() {
    return (
        <Router>
            <Switch>
                {tutorialRoutes()}
                <Route exact path={routes.index.path}>
                    <Redirect to={routes.login.url({})} />
                </Route>
                <Route path={routes.login.path}>
                    <LoginScreen />
                </Route>
                <Route path={routes.dashboard.path}>
                    <AllApplicationsScreen />
                </Route>
                <Route path={routes.inboxEvent.path}>
                    <InboxScreen terminated={false} />
                </Route>
                <Route path={routes.inbox.path}>
                    <InboxScreen terminated={false} />
                </Route>
                <Route path={routes.ledger.path}>
                    <InboxScreen terminated={true} />
                </Route>
                <Route path={routes.eventWorkload.path}>
                    <SupervisorScreen />
                </Route>
                <Route path={routes.supervisor.path}>
                    <SupervisorScreen />
                </Route>
                <Route path={routes.workflowEvent.path}>
                    <WorkflowScreen />
                </Route>
                <Route path={routes.application.path}>
                    <WorkflowScreen />
                </Route>
                <Route path={routes.requestPasswordReset.path}>
                    <RequestPasswordResetScreen />
                </Route>
                <Route path={routes.requestPasswordResetWithBlankEmail.path}>
                    <RequestPasswordResetScreen />
                </Route>
                <Route path={routes.checkPasswordResetEmail.path}>
                    <CheckPasswordResetEmailScreen />
                </Route>
                <Route path={routes.passwordChangeSuccess.path}>
                    <PasswordChangeSuccessScreen />
                </Route>
                <Route path={routes.changePassword.path}>
                    <ChangePasswordScreen />
                </Route>
                <Route path={routes.accountSettings.path}>
                    <AccountSettingsScreen />
                </Route>
                <Route path={routes.userManager.path}>
                    <UserManagerScreen />
                </Route>
            </Switch>
        </Router>
    );
}
