import { createContext, PropsWithChildren } from 'react';
import { SharedLocaleProvider, sharedSpanish } from 'lowcode-shared-code';
import { Locale } from './Locale';
import { spanish } from './spanish';

export function LocaleProvider(props: PropsWithChildren<{}>) {
    return (
        <SharedLocaleProvider sharedLocale={sharedSpanish}>
            <LocaleContext.Provider value={spanish}>
                {props.children}
            </LocaleContext.Provider>
        </SharedLocaleProvider>
    );
}

export function useLocale() {
    return spanish;
}

const LocaleContext = createContext<Locale>(null!);
