import { LocalDate } from 'js-joda';
import { Await, useGetIndicatorDataQuery } from 'lowcode-shared-code';
import React from 'react';
import _ from 'lodash';

interface IndicatorField {
    name: string;
    deltaDays?: number;
}

export function IndicatorsSection() {
    return (
        <section className="mb-8 p-4">
            <h3 className="sidebar-title text-sm font-light text-gray">
                Indicadores
            </h3>
            <IndicatorTable
                fields={[
                    { name: 'uf', deltaDays: 0 },
                    { name: 'uf', deltaDays: 1 },
                    { name: 'uf', deltaDays: 2 },
                ]}
            />
        </section>
    );
}

function IndicatorTable(props: { fields: IndicatorField[] }) {
    const { fields } = props;

    return (
        <table className="bg-primary bg-opacity-soft-primary w-full">
            <tbody>
                {fields.map(field => (
                    <IndicatorRow
                        key={`${field.name} ${field.deltaDays}`}
                        field={field}
                    />
                ))}
            </tbody>
        </table>
    );
}

function IndicatorRow(props: { field: IndicatorField }) {
    const { field } = props;
    const date = LocalDate.now().plusDays(field.deltaDays ?? 0);
    const getIndicatorDataQuery = useGetIndicatorDataQuery(field.name, date);

    const nameMap: Record<string, string> = {
        uf: 'UF',
        dolar: 'Dólar',
        utm: 'UTM',
    };

    return (
        <tr>
            <td className="font-semibold px-2 py-2">
                {nameMap[field.name] ?? (
                    <Await query={getIndicatorDataQuery}>
                        {data => <span>{data.nombre}</span>}
                    </Await>
                )}
                <span className="text-sm text-gray ml-2">
                    {formatDeltaDays(field.deltaDays)}
                </span>
            </td>
            <td className="px-2 py-2">
                <Await query={getIndicatorDataQuery}>
                    {data => (
                        <code>
                            {!_.isEmpty(data.serie)
                                ? formatClp(data.serie[0].valor)
                                : '???'}
                        </code>
                    )}
                </Await>
            </td>
        </tr>
    );
}

function formatDeltaDays(deltaDays: number | null | undefined) {
    if (!deltaDays) {
        return 'hoy';
    } else if (deltaDays === -1) {
        return 'ayer';
    } else if (deltaDays === -2) {
        return 'anteayer';
    } else if (deltaDays === 1) {
        return 'mañana';
    } else if (deltaDays === 2) {
        return 'pasado mañana';
    } else if (deltaDays < 0) {
        return `hace ${deltaDays} días`;
    } else {
        return `en ${deltaDays} días`;
    }
}

function formatClp(amount: number): string {
    return `$${amount.toLocaleString('es-CL')}`;
}
