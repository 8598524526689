import {
    Application,
    EventOverview,
    FlowOverview,
    Ref,
    UrlParams,
} from 'lowcode-shared-code';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { routes } from '../common/routes';

export function useNavigator() {
    const history = useHistory();
    const params = useParams<UrlParams>();
    const match = useRouteMatch();

    function goToDashboard() {
        history.push(routes.dashboard.url({}));
    }

    function goToApplication(application: Ref<Application>) {
        history.push(
            routes.application.url({
                applicationId: application.id,
            })
        );
    }

    function openEvent(context: {
        application: Ref<Application>;
        event: Ref<EventOverview>;
    }) {
        if (match.path === routes.inbox.path) {
            history.replace(
                routes.inboxEvent.url({
                    applicationId: context.application.id,
                    eventId: context.event.id,
                })
            );
        } else {
            history.replace(
                routes.workflowEvent.url({
                    applicationId: context.application.id,
                    eventId: context.event.id,
                })
            );
        }
    }

    function closeEvent() {
        if (match.path === routes.inboxEvent.path) {
            history.replace(
                routes.inbox.url({ terminated: params.terminated })
            );
        } else {
            history.replace(
                routes.application.url({
                    applicationId: params.applicationId!,
                })
            );
        }
    }

    function openEventWorkload(context: {
        flow: Ref<FlowOverview>;
        event: Ref<EventOverview>;
    }) {
        history.replace(
            routes.eventWorkload.url({
                flowId: context.flow.id,
                eventId: context.event.id,
            })
        );
    }

    function closeEventWorkload() {
        history.replace(routes.supervisor.url({}));
    }

    return {
        goToDashboard,
        goToApplication,
        openEvent,
        closeEvent,
        openEventWorkload,
        closeEventWorkload,
    };
}
