import {
    Await,
    ComponentsProvider,
    FlowContext,
    FlowDetail,
    FlowViewer,
    InteractivityContext,
    useComponents,
    useEventOverview,
    useGetDashboardFlowQuery,
} from 'lowcode-shared-code';
import React, { useContext } from 'react';
import { useNavigator } from '../../utils/useNavigator';
import { Popup } from 'semantic-ui-react';
import { getEventStats, Stats } from './stats';

export function SupervisorFlowViewer(props: { flow: FlowDetail }) {
    const { flow } = props;
    const navigator = useNavigator();

    return (
        <InteractivityContext.Provider
            value={{
                onSelectEvent(event) {
                    navigator.openEventWorkload({
                        flow,
                        event,
                    });
                },
            }}
        >
            <ComponentsProvider components={{ EventBlock }}>
                <FlowViewer flow={flow} paddingCells={0} />
            </ComponentsProvider>
        </InteractivityContext.Provider>
    );
}

function EventBlock() {
    const components = useComponents();
    const flow = useContext(FlowContext);
    const getDashboardFlowQuery = useGetDashboardFlowQuery(flow);
    const event = useEventOverview();

    return (
        <Popup
            trigger={
                <div>
                    <components.EventBlockContainer>
                        <components.EventBlockContent />
                        <div className="absolute top-2 right-2">
                            <ActiveCounter />
                        </div>
                    </components.EventBlockContainer>
                </div>
            }
            content={
                <div>
                    <Await query={getDashboardFlowQuery}>
                        {dashboardFlow => (
                            <div>
                                <StatsTable
                                    stats={getEventStats(dashboardFlow, event)}
                                />
                            </div>
                        )}
                    </Await>
                </div>
            }
            position="top center"
        />
    );
}

function StatsTable(props: { stats: Stats }) {
    const stats = props.stats;

    return (
        <table className="stats-table">
            <tr>
                <td>Tareas pendientes: </td>
                <td>{stats.activeTasks}</td>
            </tr>
            <tr>
                <td>Tareas terminadas: </td>
                <td>{stats.terminatedTasks}</td>
            </tr>
            <tr>
                <td>Total de tareas: </td>
                <td>{stats.totalTasks}</td>
            </tr>
        </table>
    );
}

function ActiveCounter() {
    const flow = useContext(FlowContext);
    const getDashboardFlowQuery = useGetDashboardFlowQuery(flow);
    const event = useEventOverview();

    return (
        <div
            className="rounded-full bg-red-600 text-white font-semibold flex items-center justify-center"
            style={{ minWidth: '1.75rem', height: '1.75rem' }}
        >
            <Await query={getDashboardFlowQuery} fallback={<span>...</span>}>
                {dashboardFlow => (
                    <span>
                        {getEventStats(dashboardFlow, event).activeTasks}
                    </span>
                )}
            </Await>
        </div>
    );
}
