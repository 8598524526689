import { Employee } from 'lowcode-shared-code';
import { Stats } from './stats';

export function FlowEmployeeStatsTable(props: {
    employeeStats: { employee: Employee; stats: Stats }[];
}) {
    const employeeStats = props.employeeStats;

    return (
        <table className="table">
            <thead>
                <tr>
                    <th colSpan={2}>Empleado</th>
                    <th colSpan={3}>Tareas</th>
                </tr>
                <tr>
                    <th>Nombre</th>
                    <th>Correo electrónico</th>
                    <th>Pendientes</th>
                    <th>Terminadas</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {employeeStats.map(({ employee, stats }) => (
                    <tr key={employee.profile.user.id}>
                        <td>
                            {employee.profile.user.first_name}{' '}
                            {employee.profile.user.last_name}
                        </td>
                        <td>{employee.profile.user.email}</td>
                        <td>{stats.activeTasks}</td>
                        <td>{stats.terminatedTasks}</td>
                        <td>{stats.totalTasks}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
