import React from 'react';
import { Route } from 'react-router-dom';
import { ColumnsTutorialScreen } from './ColumnsTutorialScreen';
import { CellsTutorialScreen } from './CellsTutorialScreen';

export function tutorialRoutes() {
    let routes = [];

    // const missingProjectId =
    //     process.env.REACT_APP_PROJECT_ID == null ||
    //     isBlank(process.env.REACT_APP_PROJECT_ID);
    // if (missingProjectId) {
    //     routes.push(
    //         <Route key="getting-started" path="*">
    //             <GettingStartedScreen />
    //         </Route>
    //     );
    // }

    routes.push(
        <Route key="columns" exact path="/columns-tutorial">
            <ColumnsTutorialScreen />
        </Route>,
        <Route key="cells" exact path="/cells-tutorial">
            <CellsTutorialScreen />
        </Route>
    );

    return routes;
}
