import { useParams } from 'react-router-dom';
import {
    Await,
    byId,
    EventOverview,
    EventWorkload,
    FlowOverview,
    makeRef,
    SideModal,
    Toggle,
    UrlParams,
    useFlowDetailQuery,
    useGetDashboardFlowQuery,
    User,
} from 'lowcode-shared-code';
import { useNavigator } from '../../utils/useNavigator';
import React, { ReactElement, useMemo } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import _ from 'lodash';
import { EmailLink } from '../../ui-components/EmailLink';
import { Render } from '@tdc-cl/react-object-renderer';

export function EventWorkloadSideModal() {
    const params = useParams<UrlParams>();
    const navigator = useNavigator();

    return (
        <SideModal
            data={params.eventId ?? null}
            close={navigator.closeEventWorkload}
        >
            {eventId => (
                <AwaitEventWorkload flowId={params.flowId!} eventId={eventId}>
                    {eventWorkload => (
                        <EventWorkloadDetail eventWorkload={eventWorkload} />
                    )}
                </AwaitEventWorkload>
            )}
        </SideModal>
    );
}

function AwaitEventWorkload(props: {
    flowId: FlowOverview['id'];
    eventId: EventOverview['id'];
    children(eventWorkload: EventWorkload): ReactElement;
}) {
    const { eventId } = props;
    const getDashboardFlowQuery = useGetDashboardFlowQuery(
        makeRef(props.flowId)
    );

    return (
        <Await query={getDashboardFlowQuery}>
            {dashboardFlow => {
                const eventWorkload = dashboardFlow.events.find(byId(eventId));
                if (!eventWorkload) {
                    return <p>Evento no encontrado</p>;
                }
                return props.children(eventWorkload);
            }}
        </Await>
    );
}

function EventWorkloadDetail(props: { eventWorkload: EventWorkload }) {
    const { eventWorkload } = props;
    const params = useParams<UrlParams>();
    const flowDetailQuery = useFlowDetailQuery(makeRef(params.flowId!));
    const tasksPerEmployee = useMemo(
        () => computeTasksPerEmployee(eventWorkload),
        [eventWorkload]
    );

    return (
        <div>
            <nav className="bg-white w-full h-8 border-b-2 border-gray-500 flex flex-row items-center">
                <Breadcrumb size="big">
                    <Breadcrumb.Section>
                        <Await query={flowDetailQuery}>
                            {flow => (
                                <span>
                                    {
                                        flow.events.find(byId(eventWorkload.id))
                                            ?.name
                                    }
                                </span>
                            )}
                        </Await>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon="right angle" />
                    <Breadcrumb.Section>Carga de trabajo</Breadcrumb.Section>
                </Breadcrumb>
            </nav>
            <div className="px-6 py-3">
                <Toggle label="Tareas por empleado" startsExpanded>
                    {_.isEmpty(tasksPerEmployee) ? (
                        <p>No ha habido tareas para este evento.</p>
                    ) : (
                        <table className="table">
                            <thead className="sticky top-0">
                                <tr>
                                    <th>Empleado</th>
                                    <th>Correo electrónico</th>
                                    <th>Pendientes</th>
                                    <th>Terminadas</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.sortBy(tasksPerEmployee, 'activeTasks').map(
                                    row => (
                                        <tr key={row.employeeId}>
                                            <td>{row.fullName}</td>
                                            <td>
                                                <EmailLink email={row.email} />
                                            </td>
                                            <td>{row.activeTasks}</td>
                                            <td>{row.terminatedTasks}</td>
                                            <td>
                                                {row.activeTasks +
                                                    row.terminatedTasks}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    )}
                </Toggle>
                <Toggle label="Objeto JSON">
                    <Render value={eventWorkload} />
                </Toggle>
            </div>
        </div>
    );
}

interface TasksPerEmployeeRow {
    employeeId: string;
    fullName: string;
    email: string;
    activeTasks: number;
    terminatedTasks: number;
}

function computeTasksPerEmployee(
    eventWorkload: EventWorkload
): TasksPerEmployeeRow[] {
    const active = eventWorkload.active_employees.map(({ profile, tasks }) => ({
        employeeId: profile.user.id,
        email: profile.user.email,
        fullName: fullName(profile.user),
        activeTasks: tasks,
    }));
    const terminated = eventWorkload.terminated_employees.map(
        ({ profile, tasks }) => ({
            employeeId: profile.user.id,
            email: profile.user.email,
            fullName: fullName(profile.user),
            terminatedTasks: tasks,
        })
    );
    const dataByEmployee = _.groupBy([...active, ...terminated], 'employeeId');
    return _.map(dataByEmployee, rows =>
        _.merge({ activeTasks: 0, terminatedTasks: 0 }, ...rows)
    );
}

function fullName(user: User): string {
    return `${user.first_name} ${user.last_name}`;
}
