import { useState } from 'react';
import { ModalProps } from 'semantic-ui-react';

export function useModal<T>(): IModal<T> {
    const [state, setState] = useState<ModalState>(new Closed());
    const isOpen = state instanceof Open;
    const data = state instanceof Open ? state.data : undefined;

    function open(data: T) {
        setState(new Open(data));
    }

    function close() {
        setState(new Closed());
    }

    const props = {
        open: isOpen,
        onClose: close,
    };

    return { isOpen, open, close, props, data };
}

export interface IModal<T> {
    isOpen: boolean;
    open(data: T): void;
    close(): void;
    props: ModalProps;
    data: T | undefined;
}

abstract class ModalState {}

class Open<T> extends ModalState {
    constructor(readonly data: T) {
        super();
    }
}

class Closed extends ModalState {}
