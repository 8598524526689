import React from 'react';
import { Breadcrumb, Modal, ModalProps } from 'semantic-ui-react';
import { useDashboard } from '../screens/dashboard/dashboard';
import { useQuery } from 'react-query';
import {
    Action,
    Await,
    endpoints,
    EventDetail,
    FlowOverview,
    FormOverview,
    LowcodeFormData,
    makeRef,
    Ref,
    RenderForm,
} from 'lowcode-shared-code';
import { Warning } from '../ui-components/Warning';
import { useNavigator } from '../utils/useNavigator';
import { usePostApplicationMutation } from '../common/mutations';
import { useLocale } from '../locales/localeContext';

export function InitialFormModal(props: ModalProps) {
    const { initialFormModal } = useDashboard();
    const selectedFlow = initialFormModal.data;

    return (
        <Modal {...props} className="w-max">
            <Modal.Header>
                <Breadcrumb size="big">
                    <Breadcrumb.Section>
                        {selectedFlow?.name}
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon="right angle" />
                    <Breadcrumb.Section>Iniciar solicitud</Breadcrumb.Section>
                </Breadcrumb>
            </Modal.Header>
            <Modal.Content className="bg-gray-200 flex flex-column w-full items-center justify-center">
                {selectedFlow && <StartEvent flow={selectedFlow} />}
            </Modal.Content>
        </Modal>
    );
}

function StartEvent(props: { flow: FlowOverview }) {
    const startEvent = { id: props.flow.start_event };
    const startEventDetailQuery = useQuery(
        [endpoints.eventDetail.url, startEvent.id],
        () => endpoints.eventDetail.fetch(startEvent.id)
    );

    return (
        <Await query={startEventDetailQuery}>
            {startEvent => {
                const initialFormId = getInitialFormId(startEvent);
                if (initialFormId == null) {
                    return (
                        <Warning>
                            El evento inicial no tiene ningún formulario
                            asignado.
                        </Warning>
                    );
                }
                return (
                    <InitialForm
                        form={makeRef(initialFormId)}
                        flow={props.flow}
                        action={getForwardAction(startEvent)}
                    />
                );
            }}
        </Await>
    );
}

function InitialForm(props: {
    form: Ref<FormOverview>;
    flow: FlowOverview;
    action: Action;
}) {
    const locale = useLocale();
    const navigator = useNavigator();
    const postApplicationMutation = usePostApplicationMutation();

    const formDetailQuery = useQuery(
        [endpoints.formDetail.url, props.form.id],
        () => endpoints.formDetail.fetch(props.form.id)
    );

    async function onSubmit(formData: LowcodeFormData) {
        const newApplication = await postApplicationMutation.mutateAsync({
            flow: props.flow.id,
            action: props.action.id,
            initial_form_data: formData,
        });
        await sleep(200);
        navigator.goToApplication(newApplication);
    }

    return (
        <div className="w-64">
            <Await query={formDetailQuery}>
                {formDetail => (
                    <RenderForm
                        formDetail={formDetail}
                        submitLabel={locale.startApplication}
                        submittingLabel={locale.startingApplication}
                        onSubmit={onSubmit}
                    />
                )}
            </Await>
        </div>
    );
}

async function sleep(ms: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

function getInitialFormId(event: EventDetail): FormOverview['id'] | null {
    return event.sections[0]?.tabs[0]?.form;
}

function getForwardAction(event: EventDetail): Action {
    return event.actions[0];
}
