import { Screen } from '../Screen';
import { Link, useParams } from 'react-router-dom';
import { routes } from '../../common/routes';
import { ReturnToLoginLink } from './ReturnToLoginLink';
import { List } from 'semantic-ui-react';

export function CheckPasswordResetEmailScreen() {
    const params = useParams<{ email: string }>();

    return (
        <Screen
            public
            className="bg-neutral flex flex-col items-center justify-center"
        >
            <div className="max-w-md">
                <h1>Enlace de recuperación enviado</h1>
                <p>
                    Hemos enviado un enlace de recuperación de contraseña a tu
                    correo <a href={'mailto:' + params.email}>{params.email}</a>
                    .
                </p>

                <h4>¿No ha llegado?</h4>
                <List bulleted className="mb-8">
                    <List.Item>
                        Ten en cuenta que la llegada del correo podría demorar
                        unos minutos.
                    </List.Item>
                    <List.Item>
                        Verifica que el correo sea el mismo de tu cuenta.
                    </List.Item>
                    <List.Item>
                        Si aún así no llega, puedes{' '}
                        <Link
                            to={routes.requestPasswordReset.url({
                                email: params.email,
                            })}
                        >
                            volver a solicitar el envío
                        </Link>
                        .
                    </List.Item>
                </List>

                <ReturnToLoginLink />
            </div>
        </Screen>
    );
}
