import { Screen } from '../Screen';
import {
    EmailField,
    FormContainer,
    FormPreview,
    SubmitButton,
} from 'lowcode-shared-code';
import { useRequestPasswordResetMutation } from '../../common/mutations';
import React from 'react';
import { ReturnToLoginLink } from './ReturnToLoginLink';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from '../../common/routes';

export function RequestPasswordResetScreen() {
    const requestPasswordResetMutation = useRequestPasswordResetMutation();
    const history = useHistory();
    const params = useParams<{ email?: string }>();

    async function requestEmail(formValues: { email: string }) {
        const { email } = formValues;
        await requestPasswordResetMutation.mutateAsync({ email });
        history.replace(routes.checkPasswordResetEmail.url({ email }));
    }

    return (
        <Screen
            public
            className="bg-neutral flex flex-col items-center justify-center"
        >
            <div className="max-w-md">
                <h1>Recuperación de contraseña</h1>
                <p>
                    Si olvidaste tu contraseña, podrás solicitar que te enviemos
                    un enlace de recuperación de contraseña al correo
                    electrónico correspondiente a tu cuenta.
                </p>
                <FormPreview
                    submitLabel="Solicitar enlace de recuperación"
                    submittingLabel="Solicitando enlace de recuperación"
                    onInvalid="disable"
                    disableReason="Debes ingresar un correo electrónico válido para poder solicitar el enlace de recuperación."
                    onSubmit={requestEmail}
                    defaultValues={params}
                >
                    <FormContainer>
                        <EmailField
                            name="email"
                            label="Correo electrónico de tu cuenta"
                        />
                        <SubmitButton />
                    </FormContainer>
                </FormPreview>
                <ReturnToLoginLink />
            </div>
        </Screen>
    );
}
