import { Screen } from '../Screen';
import { SideBar } from '../../ui-components/SideBar';
import {
    Await,
    FlowContext,
    FlowDetail,
    Toggle,
    useGetDashboardFlowQuery,
    useGetEmployeesQuery,
    useGetFlowsQuery,
    useToggleSections,
} from 'lowcode-shared-code';
import React from 'react';
import _ from 'lodash';
import './SupervisorScreen.css';
import { Icon } from 'semantic-ui-react';
import { EventWorkloadSideModal } from './EventWorkloadSideModal';
import { SupervisorFlowViewer } from './SupervisorFlowViewer';
import { FlowEmployeeStatsTable } from './FlowEmployeeStatsTable';
import { getEmployeeStats } from './stats';

export function SupervisorScreen() {
    const getFlowsQuery = useGetFlowsQuery();

    return (
        <Screen className="flex flex-row">
            <SideBar />
            <main className="flex-1 bg-neutral p-10">
                <Await query={getFlowsQuery}>
                    {flows => <FlowSections flows={flows as FlowDetail[]} />}
                </Await>
                <EventWorkloadSideModal />
            </main>
        </Screen>
    );
}

function FlowSections(props: { flows: FlowDetail[] }) {
    const { flows } = props;
    const flowSections = useToggleSections({
        sectionIds: _.map(flows, 'id'),
        initiallyExpanded: 'all',
    });

    return (
        <div>
            {flows.length >= 2 && (
                <div className="mb-4">
                    <span>
                        <Icon name="lightbulb" color="yellow" /> Puedes hacer
                        click en cualquier evento para ver más detalles.
                    </span>
                    <button
                        className="btn-text text-gray disabled:cursor-auto float-right"
                        onClick={flowSections.collapseAll}
                        disabled={flowSections.areAllCollapsed}
                    >
                        Colapsar todo
                    </button>
                    <button
                        className="btn-text text-gray disabled:cursor-auto float-right"
                        onClick={flowSections.expandAll}
                        disabled={flowSections.areAllExpanded}
                    >
                        Expandir todo
                    </button>
                </div>
            )}
            {flows.map(flow => (
                <FlowContext.Provider key={flow.id} value={flow}>
                    <FlowSection
                        key={flow.id}
                        flow={flow}
                        expanded={flowSections.isExpanded(flow.id)}
                        onChangeExpanded={expanded =>
                            flowSections.setIsExpanded(flow.id, expanded)
                        }
                    />
                </FlowContext.Provider>
            ))}
        </div>
    );
}

function FlowSection(props: {
    flow: FlowDetail;
    expanded: boolean;
    onChangeExpanded(expanded: boolean): void;
}) {
    const { flow } = props;

    return (
        <Toggle
            label={flow.name}
            expanded={props.expanded}
            onChange={props.onChangeExpanded}
        >
            <SupervisorFlowViewer flow={flow} />
            <FlowEmployeeStats flow={flow} />
        </Toggle>
    );
}

function FlowEmployeeStats(props: { flow: FlowDetail }) {
    const flow = props.flow;
    const getDashboardFlowQuery = useGetDashboardFlowQuery(flow);
    const getEmployeesQuery = useGetEmployeesQuery();

    return (
        <Await query={getDashboardFlowQuery}>
            {dashboardFlow => (
                <Await query={getEmployeesQuery}>
                    {employees => (
                        <FlowEmployeeStatsTable
                            employeeStats={employees.map(employee => ({
                                employee,
                                stats: getEmployeeStats(
                                    dashboardFlow,
                                    employee
                                ),
                            }))}
                        />
                    )}
                </Await>
            )}
        </Await>
    );
}
