import { User } from 'lowcode-shared-code';
import { Icon } from 'semantic-ui-react';
import { EmailLink } from '../../ui-components/EmailLink';
import React from 'react';

export function UserSummary(props: { user: User }) {
    const { first_name, last_name, email } = props.user;
    return (
        <span>
            {first_name} {last_name}{' '}
            <div className="flex flex-row">
                <Icon name="mail" color="grey" className="ml-4" />
                <EmailLink email={email} />
            </div>
        </span>
    );
}
