import { Screen } from '../Screen';
import { Icon, Message } from 'semantic-ui-react';
import { ReturnToLoginLink } from './ReturnToLoginLink';

export function PasswordChangeSuccessScreen() {
    return (
        <Screen
            public
            className="bg-neutral flex flex-col items-center justify-center"
        >
            <div className="max-w-md">
                <h1>
                    <Icon name="check circle" color="green" /> Cambio de
                    contraseña
                </h1>
                <Message positive>
                    Tu contraseña ha sido cambiada con éxito. Ahora podrás
                    iniciar sesión con tu nueva contraseña.
                </Message>
                <ReturnToLoginLink />
            </div>
        </Screen>
    );
}
