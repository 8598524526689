import {
    Await,
    EventContext,
    FormOverview,
    LowcodeFormData,
    makeFormData,
    makeRef,
    ReadOnlyProvider,
    Ref,
    RenderForm,
    TabOverview,
    useFormDetailQuery,
} from 'lowcode-shared-code';
import React, { useContext } from 'react';
import { useApplication } from '../../utils/applicationContext';
import { usePatchFormDataMutation } from '../../common/mutations';
import { useLocale } from '../../locales/localeContext';
import { useEventStatus } from './useEventStatus';

export function TabForm(props: { tab: TabOverview }) {
    const locale = useLocale();
    const application = useApplication();
    const event = useContext(EventContext);
    const eventStatus = useEventStatus(event, props.tab);
    const form = makeRef(props.tab.form!) as Ref<FormOverview>;
    const formDetailQuery = useFormDetailQuery(form);
    const patchFormDataMutation = usePatchFormDataMutation({
        application,
        form,
    });

    async function onSubmit(formValues: LowcodeFormData) {
        const formData = makeFormData(formValues);
        await patchFormDataMutation.mutateAsync(formData);
    }

    return (
        <ReadOnlyProvider readOnly={eventStatus.disableReason != null}>
            <Await query={formDetailQuery}>
                {formDetail => (
                    <RenderForm
                        formDetail={formDetail}
                        onSubmit={props.tab.readonly ? undefined : onSubmit}
                        defaultValues={application.application_data}
                        submitLabel={locale.saveChanges}
                        submittingLabel={locale.savingChanges}
                        disableReason={eventStatus.disableReason}
                    />
                )}
            </Await>
        </ReadOnlyProvider>
    );
}
