import { SideBar } from '../../ui-components/SideBar';
import {
    Await,
    EventSideModal,
    InboxTask,
    makeRef,
    UrlParams,
    useApplicationDetailQuery,
    useTimestampFormatter,
} from 'lowcode-shared-code';
import React from 'react';
import _ from 'lodash';
import { ZonedDateTime } from 'js-joda';
import { Button } from 'semantic-ui-react';
import { useNavigator } from '../../utils/useNavigator';
import { EventSideModalProvider } from '../workflow/EventSideModalProvider';
import { InteractivityProvider } from '../workflow/InteractivityProvider';
import { ApplicationProvider } from '../../utils/applicationContext';
import { Screen } from '../Screen';
import { useParams } from 'react-router-dom';
import { HeaderSection } from '../dashboard/HeaderSection';
import './InboxTable.css';
import { DashboardProvider } from '../dashboard/dashboard';
import emptyInboxIllustration from '../../assets/empty-inbox.svg';
import { PaginatedItems } from '../../ui-components/PaginatedItems';
import { useInboxQuery, useLedgerQuery } from './queries';

export function InboxScreen(props: { terminated: boolean }) {
    const params = useParams<UrlParams>();
    const applicationDetailQuery = useApplicationDetailQuery(
        makeRef(params.applicationId!)
    );

    return (
        <Screen className="flex flex-row">
            <DashboardProvider>
                <SideBar />
                <main className="flex-1 bg-neutral p-10">
                    {props.terminated ? (
                        <LedgerSection />
                    ) : (
                        <React.Fragment>
                            <HeaderSection />
                            <InboxSection />
                        </React.Fragment>
                    )}
                    {params.applicationId && (
                        <Await query={applicationDetailQuery}>
                            {application => (
                                <ApplicationProvider application={application}>
                                    <InteractivityProvider>
                                        <EventSideModalProvider>
                                            <EventSideModal
                                                eventId={params.eventId}
                                            />
                                        </EventSideModalProvider>
                                    </InteractivityProvider>
                                </ApplicationProvider>
                            )}
                        </Await>
                    )}
                </main>
            </DashboardProvider>
        </Screen>
    );
}

function InboxSection() {
    return (
        <section className="mb-8">
            <h3 className="text-xl font-semibold">Bandeja de entrada</h3>
            <PaginatedItems
                useQuery={useInboxQuery}
                page_size={10}
                fallback={<EmptyInbox />}
            >
                {tasks => <InboxTable tasks={tasks} />}
            </PaginatedItems>
        </section>
    );
}

function LedgerSection() {
    return (
        <section className="mb-8">
            <h3 className="text-xl font-semibold">Tareas terminadas</h3>
            <PaginatedItems
                useQuery={useLedgerQuery}
                page_size={10}
                fallback={<div>No hay tareas terminadas aún.</div>}
            >
                {tasks => <InboxTable tasks={tasks} />}
            </PaginatedItems>
        </section>
    );
}

function InboxTable(props: { tasks: InboxTask[] }) {
    const { tasks } = props;

    return (
        <table className="inbox-table w-full">
            <thead>
                <tr className="bg-neutral border-none">
                    <th align="left">Solicitud</th>
                    <th align="left">Flujo</th>
                    <th align="left">Tarea</th>
                    <th align="left">Fecha</th>
                    <th align="left">Acciones</th>
                </tr>
            </thead>
            <tbody>
                {tasks.map(task => (
                    <TaskRow key={task.id} task={task} />
                ))}
            </tbody>
        </table>
    );
}

function TaskRow(props: { task: InboxTask }) {
    const { task } = props;
    const { application } = task;
    const { flow } = application;
    const { name, rut } = application.application_data;
    const formatTimestamp = useTimestampFormatter();
    const navigator = useNavigator();
    const event = task.event;

    return (
        <tr
            className="h-12 border border-gray-300 bg-white hover:bg-yellow-50 cursor-pointer"
            onClick={() => navigator.openEvent({ application, event })}
        >
            <td>
                {name} &ndash; {rut}
            </td>
            <td>{flow.name}</td>
            <td>{event.name}</td>
            <td>{formatTimestamp(ZonedDateTime.parse(task.created_at))}</td>
            <td>
                <Button
                    icon="external"
                    onClick={event => {
                        navigator.goToApplication(application);
                        event.stopPropagation();
                    }}
                />
            </td>
        </tr>
    );
}

function EmptyInbox() {
    return (
        <div className="text-center">
            <p className="text-xl">Tu bandeja de entrada está vacía</p>
            <p className="text-lg">
                Cuando tengas tareas por hacer, aparecerán aquí
            </p>
            <img src={emptyInboxIllustration} alt="" />
        </div>
    );
}
