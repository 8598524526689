import { PropsWithChildren, useContext } from 'react';
import { FlowContext, FlowDetail } from 'lowcode-shared-code';

export function FlowProvider(
    props: PropsWithChildren<{
        flow: FlowDetail;
    }>
) {
    return (
        <FlowContext.Provider value={props.flow}>
            {props.children}
        </FlowContext.Provider>
    );
}

export function useFlow(): FlowDetail {
    return useContext(FlowContext);
}

// const FlowContext = createContext<FlowDetail>(null!);
