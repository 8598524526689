import { useHistory, useParams } from 'react-router-dom';
import { useRecoverPasswordMutation } from '../../common/mutations';
import React from 'react';
import {
    FormContainer,
    FormPreview,
    PasswordField,
    SubmitButton,
} from 'lowcode-shared-code';
import { Screen } from '../Screen';
import { ReturnToLoginLink } from './ReturnToLoginLink';
import { routes } from '../../common/routes';
import {
    NewPasswordField,
    RepeatPasswordField,
} from '../../custom-fields/fields';

export function ChangePasswordScreen() {
    const params = useParams<{ token?: string }>();
    const recoverPasswordMutation = useRecoverPasswordMutation();
    const history = useHistory();

    async function changePassword(formValues: { new_password: string }) {
        await recoverPasswordMutation.mutateAsync({
            new_password: formValues.new_password,
            token: params.token!,
        });
        history.replace(routes.passwordChangeSuccess.url({}));
    }

    return (
        <Screen
            public
            className="bg-neutral flex flex-col items-center justify-center"
        >
            <div className="max-w-md">
                <h1>Cambio de contraseña</h1>
                <p>
                    Por motivos de seguridad, no podemos mostrarte tu contraseña
                    actual. Sin embargo, podrás cambiar tu contraseña por una
                    nueva.
                </p>
                <FormPreview
                    submitLabel="Cambiar contraseña"
                    submittingLabel="Cambiando contraseña"
                    onSubmit={changePassword}
                    onInvalid="disable"
                    disableReason="Ingresa tu nueva contraseña para continuar."
                >
                    <FormContainer>
                        <NewPasswordField />
                        <RepeatPasswordField
                            relatedName="new_password"
                            label="Repetir contraseña nueva"
                        />
                        <SubmitButton />
                    </FormContainer>
                </FormPreview>
                <ReturnToLoginLink />
            </div>
        </Screen>
    );
}
