import { createContext, PropsWithChildren, useContext } from 'react';
import { Application } from 'lowcode-shared-code';

export function ApplicationProvider(
    props: PropsWithChildren<{
        application: Application;
    }>
) {
    return (
        <ApplicationContext.Provider value={props.application}>
            {props.children}
        </ApplicationContext.Provider>
    );
}

export function useApplication(): Application {
    return useContext(ApplicationContext);
}

const ApplicationContext = createContext<Application>(null!);
