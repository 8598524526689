import React, { ReactElement } from 'react';
import { UseQueryResult } from 'react-query';
import {
    Await,
    LinkButton,
    Paginated,
    useQueryParams,
} from 'lowcode-shared-code';
import _ from 'lodash';
import classNames from 'classnames';
import { IPaginationQueryParams } from 'lowcode-shared-code';

export function PaginatedItems<T>(props: {
    useQuery(params: IPaginationQueryParams): UseQueryResult<Paginated<T>>;
    page_size: number;
    fallback: ReactElement;
    children(results: T[]): ReactElement;
}) {
    const { page_size, fallback } = props;
    const queryParams = useQueryParams<{ page: string }>();
    const currentPage = Number(queryParams.getValue('page') ?? 1);
    const paginatedItemsQuery = props.useQuery({
        page: currentPage,
        page_size,
    });

    return (
        <Await query={paginatedItemsQuery}>
            {({ count, results }) =>
                count === 0 ? (
                    fallback
                ) : (
                    <div>
                        {props.children(results)}
                        <PaginationBar
                            currentPage={currentPage}
                            numPages={Math.ceil(count / page_size - 1e-8)}
                            onChangePage={page =>
                                queryParams.setValue('page', page.toString())
                            }
                        />
                    </div>
                )
            }
        </Await>
    );
}

function PaginationBar(props: {
    currentPage: number;
    numPages: number;
    onChangePage(page: number): void;
}) {
    const { currentPage, numPages, onChangePage } = props;

    return (
        <div className="flex flex-row flex-wrap">
            <button
                className={classNames(
                    'btn-text',
                    currentPage === 1 && 'invisible'
                )}
                onClick={() => onChangePage(currentPage - 1)}
            >
                Anterior
            </button>
            {numPages > 1 &&
                _.range(1, numPages + 1).map(page => (
                    <LinkButton
                        onClick={() => onChangePage(page)}
                        disabled={page === currentPage}
                    >
                        {page}
                    </LinkButton>
                ))}
            <button
                className={classNames(
                    'btn-text',
                    currentPage === numPages && 'invisible'
                )}
                onClick={() => onChangePage(currentPage + 1)}
            >
                Siguiente
            </button>
        </div>
    );
}
