import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { FormContainer, FormPreview, SubmitButton } from 'lowcode-shared-code';
import { StagesField, StatesField } from '../../custom-fields/fields';

export function FiltersDropdown(props: { disabled?: boolean }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown
            text="Filtrar"
            icon="filter"
            floating
            button
            className="icon flex items-center justify-center bg-transparent border-2 border-solid border-gray-500 px-10"
            open={isOpen}
            onClick={() => setIsOpen(x => !x)}
            disabled={props.disabled}
        >
            <Dropdown.Menu direction="left">
                <Dropdown.Item>
                    <FormPreview submitLabel="Aplicar">
                        <FormContainer className="w-72">
                            <StatesField />
                            <StagesField />
                            <div className={'flex justify-end'}>
                                <button
                                    onClick={event => {
                                        setIsOpen(false);
                                        event.stopPropagation();
                                    }}
                                    type="button"
                                >
                                    Cancelar
                                </button>
                                <SubmitButton className="w-auto px-4 ml-4" />
                            </div>
                        </FormContainer>
                    </FormPreview>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
