import React, {
    Dispatch,
    PropsWithChildren,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    byId,
    Description,
    EventContext,
    EventModalRendering,
    EventSideModalContext,
    getTabs,
    IEventSideModalContext,
    makeRef,
    SideModal,
    sorted,
    TabOverview,
    UrlParams,
    useEventDetailQuery,
} from 'lowcode-shared-code';
import { TabForm } from './TabForm';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { EventActions } from './EventActions';

export function EventSideModalProvider(props: PropsWithChildren<{}>) {
    const [selectedTabId, setSelectedTabId] = useSelectedTabIdState();

    const context: IEventSideModalContext = {
        tabContent(tab: TabOverview): ReactElement {
            return <TabForm tab={tab} />;
        },
        selectedTabId,
        setSelectedTabId,
    };

    return (
        <EventModalRendering.ComponentsProvider components={{ HeaderContent }}>
            <EventSideModalContext.Provider value={context}>
                {props.children}
            </EventSideModalContext.Provider>
        </EventModalRendering.ComponentsProvider>
    );
}

function useSelectedTabIdState(): [
    TabOverview['id'] | undefined,
    Dispatch<SetStateAction<TabOverview['id'] | undefined>>
] {
    const params = useParams<UrlParams>();
    const eventDetailQuery = useEventDetailQuery(makeRef(params.eventId!));
    const [selectedTabId, setSelectedTabId] = useState<
        TabOverview['id'] | undefined
    >(undefined);
    const sections = sorted(eventDetailQuery.data?.sections ?? []);
    const tabs = getTabs({ sections });
    const firstTab = _.first(tabs);

    useEffect(() => {
        if (!_.isUndefined(firstTab) && !tabs.find(byId(selectedTabId))) {
            setSelectedTabId(firstTab.id);
        }
    }, [firstTab]);

    return [selectedTabId, setSelectedTabId];
}

function HeaderContent() {
    const event = useContext(EventContext);

    return (
        <React.Fragment>
            <div className="float-right">
                <EventActions event={event} />
            </div>
            <SideModal.Title>{event.name}</SideModal.Title>
            <Description item={event} />
        </React.Fragment>
    );
}
