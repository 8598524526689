import { IModal, useModal } from '../../modals/modals';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { InitialFormModal } from '../../modals/InitialFormModal';
import { FlowOverview } from 'lowcode-shared-code';

interface Dashboard {
    initialFormModal: IModal<FlowOverview>;
}

export function DashboardProvider(props: PropsWithChildren<{}>) {
    const initialFormModal = useModal<FlowOverview>();
    const dashboard = { initialFormModal };

    return (
        <DashboardContext.Provider value={dashboard}>
            {props.children}
            <InitialFormModal {...initialFormModal.props} />
        </DashboardContext.Provider>
    );
}

export function useDashboard(): Dashboard {
    return useContext(DashboardContext);
}

const DashboardContext = createContext<Dashboard>(null!);
