import {
    ChecklistField,
    FormContainer,
    FormPreview,
    quote,
} from 'lowcode-shared-code';
import { Button, Modal, ModalProps } from 'semantic-ui-react';
import { ActionValidationResult } from '../utils/validateAction';

export function InvalidActionModal(
    props: ModalProps & { actionValidationResult: ActionValidationResult }
) {
    const { action, validTabs } = props.actionValidationResult;

    return (
        <Modal size="tiny" {...props}>
            <Modal.Header className="bg-primary text-primary">
                Tarea incompleta
            </Modal.Header>
            <Modal.Content>
                {action && (
                    <FormPreview
                        defaultValues={{
                            validTabs,
                        }}
                    >
                        <FormContainer>
                            <ChecklistField
                                name="validTabs"
                                label={`Para ${quote(
                                    action.name
                                )} debe completar:`}
                                options={action.tabs.map(tab => ({
                                    value: tab.id,
                                    label: tab.name,
                                }))}
                                readOnly
                            />
                        </FormContainer>
                    </FormPreview>
                )}
            </Modal.Content>
            <Modal.Actions className="bg-neutral">
                <Button onClick={() => props.onClose?.(null!, null!)}>
                    Volver al evento
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
