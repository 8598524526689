import React, { createContext, PropsWithChildren, useContext } from 'react';
import logoPlaceholder from '../assets/logo-placeholder.png';
import { getEnvironmentVariable } from 'lowcode-shared-code';

export function ProjectProvider(props: PropsWithChildren<{}>) {
    const augmentedProject = {
        name: getEnvironmentVariable('REACT_APP_PROJECT_NAME'),
        subtitle: getEnvironmentVariable('REACT_APP_PROJECT_DESCRIPTION'),
        logo: process.env.REACT_APP_LOGO_URL || logoPlaceholder,
    };
    return (
        <ProjectContext.Provider value={augmentedProject}>
            {props.children}
        </ProjectContext.Provider>
    );
}

const ProjectContext = createContext<{
    name: string;
    subtitle: string;
    logo: string;
}>(null!);

export function useProject() {
    return useContext(ProjectContext);
}
