import { PropsWithChildren, useState } from 'react';
import {
    Action,
    EventOverview,
    IInteractivityContext,
    InteractivityContext,
    isBlank,
    makeRef,
    TabOverview,
    UrlParams,
} from 'lowcode-shared-code';
import { useNavigator } from '../../utils/useNavigator';
import { useApplication } from '../../utils/applicationContext';
import { usePostExecutionMutation } from '../../common/mutations';
import { useParams } from 'react-router-dom';
import { ConfirmationPromptModal } from '../../ui-components/ConfirmationPromptModal';
import { useEventStatus } from './useEventStatus';
import { InvalidActionModal } from '../../ui-components/InvalidActionModal';
import { validateAction } from '../../utils/validateAction';

export function InteractivityProvider(props: PropsWithChildren<{}>) {
    const params = useParams<UrlParams>();
    const navigator = useNavigator();
    const application = useApplication();
    const postExecutionMutation = usePostExecutionMutation({ application });
    const eventStatus = useEventStatus(makeRef(params.eventId!));
    const [promptAction, setPromptAction] = useState<Action | null>(null);
    const [actionValidationResult, setActionValidationResult] =
        useState<ActionValidationResult | null>(null);

    async function validateThenExecute(action: Action) {
        const result = validateAction(action, application.application_data);
        if (result.isValid) {
            await postExecutionMutation.mutateAsync(action);
            navigator.closeEvent();
        } else {
            setActionValidationResult(result);
        }
    }

    const context: IInteractivityContext = {
        onSelectEvent(event) {
            navigator.openEvent({
                application,
                event: event as EventOverview,
            });
        },
        onCloseEventSideModal: navigator.closeEvent,
        async onClickAction(action) {
            if (isBlank(action.confirmation_prompt)) {
                await validateThenExecute(action);
            } else {
                setPromptAction(action);
            }
        },
        actionDisabledReason: eventStatus.disableReason,
    };

    return (
        <InteractivityContext.Provider value={context}>
            {props.children}

            {promptAction && (
                <ConfirmationPromptModal
                    action={promptAction}
                    onContinue={() => validateThenExecute(promptAction)}
                    onClose={() => setPromptAction(null)}
                    open
                />
            )}

            {actionValidationResult && (
                <InvalidActionModal
                    actionValidationResult={actionValidationResult}
                    onClose={() => setActionValidationResult(null)}
                    open={!actionValidationResult.isValid}
                />
            )}
        </InteractivityContext.Provider>
    );
}

interface ActionValidationResult {
    action: Action;
    validTabs: Array<TabOverview['id']>;
    isValid: boolean;
}
