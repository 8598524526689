import {
    Application,
    EventOverview,
    FlowOverview,
    Route,
    sharedRoutes,
} from 'lowcode-shared-code';

/** An object with all the routes that will be used with react-router.
 *
 * Having all routes contained in a single object is useful to avoid
 * hardcoding of the path and url strings across different files. */
export const routes = {
    ...sharedRoutes,

    dashboard: new Route<{}>('/dashboard'),

    inboxEvent: new Route<{
        applicationId: Application['id'];
        eventId: EventOverview['id'];
    }>('/inbox/solicitud/:applicationId/evento/:eventId'),

    inbox: new Route<{}>('/inbox'),
    ledger: new Route<{}>('/ledger'),

    eventWorkload: new Route<{
        flowId: FlowOverview['id'];
        eventId: EventOverview['id'];
    }>('/supervisor/flujo/:flowId/evento/:eventId'),

    supervisor: new Route<{}>('/supervisor'),

    workflowEvent: new Route<{
        applicationId: Application['id'];
        eventId: EventOverview['id'];
    }>('/workflow/solicitud/:applicationId/evento/:eventId'),

    application: new Route<{ applicationId: Application['id'] }>(
        '/workflow/solicitud/:applicationId'
    ),

    accountSettings: new Route<{}>('/configuracion-de-la-cuenta'),

    userManager: new Route<{}>('/registro-usuario'),
};
