import { Screen } from '../Screen';
import { SideBar } from '../../ui-components/SideBar';
import {
    Await,
    byId,
    EmailField,
    emailRegex,
    Employee,
    FormContainer,
    FormPreview,
    PasswordField,
    Role,
    SubmitButton,
    TextField,
    useGetEmployeesQuery,
    useGetRolesQuery,
} from 'lowcode-shared-code';
import { RepeatPasswordField } from '../../custom-fields/fields';
import { Button, Message, Modal, ModalProps } from 'semantic-ui-react';
import { FetchError, isFetchError } from '@tdc-cl/async-stuff';
import _ from 'lodash';
import React, { useState } from 'react';
import Immutable from 'immutable';
import {
    useDeleteEmployeeMutation,
    usePatchEmployeeMutation,
    useSignUpMutation,
} from '../../common/mutations';
import { EmailLink } from '../../ui-components/EmailLink';

function EmployeesTable() {
    const getRolesQuery = useGetRolesQuery();
    const getEmployeesQuery = useGetEmployeesQuery();

    return (
        <Await query={getRolesQuery}>
            {projectRoles => (
                <table className="table">
                    <thead className="sticky top-0">
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Correo electrónico</th>
                            {projectRoles.map(role => (
                                <th key={role.id}>{role.name}</th>
                            ))}
                            <th>Acciones</th>
                        </tr>
                    </thead>

                    <Await query={getEmployeesQuery}>
                        {employees => (
                            <tbody>
                                {employees.map(employee => (
                                    <EmployeeRow
                                        key={employee.profile.user.id}
                                        employee={employee}
                                        projectRoles={projectRoles}
                                    />
                                ))}
                            </tbody>
                        )}
                    </Await>
                </table>
            )}
        </Await>
    );
}

function EmployeeRow(props: { employee: Employee; projectRoles: Role[] }) {
    const { employee, projectRoles } = props;
    const [mode, setMode] = useState<'read' | 'write'>('read');

    if (mode === 'read') {
        return (
            <ReadEmployeeRow
                employee={employee}
                projectRoles={projectRoles}
                onClickEdit={() => setMode('write')}
            />
        );
    } else {
        return (
            <WriteEmployeeRow
                employee={employee}
                projectRoles={projectRoles}
                onChangesSaved={() => setMode('read')}
                onClickCancel={() => setMode('read')}
            />
        );
    }
}

function ReadEmployeeRow(props: {
    employee: Employee;
    projectRoles: Role[];
    onClickEdit(): void;
}) {
    const { employee, projectRoles, onClickEdit } = props;

    return (
        <tr>
            <td>{employee.profile.user.id}</td>
            <td>{employee.profile.user.first_name}</td>
            <td>{employee.profile.user.last_name}</td>
            <td>
                <EmailLink email={employee.profile.user.email} />
            </td>
            {projectRoles.map(role => (
                <td key={role.id} align="center">
                    <input
                        type="checkbox"
                        checked={employee.roles.some(byId(role.id))}
                        style={{ transform: 'scale(1.5)' }}
                        disabled
                    />
                </td>
            ))}
            <td>
                <div className="flex flex-row">
                    <Button icon="edit" onClick={onClickEdit} />
                    <DeleteEmployeeButton employee={employee} />
                </div>
            </td>
        </tr>
    );
}

function DeleteEmployeeButton(props: { employee: Employee }) {
    const { employee } = props;
    const deleteEmployeeMutation = useDeleteEmployeeMutation();

    function onClick() {
        const { id, first_name, last_name, email } = employee.profile.user;
        if (
            window.confirm(`¿Eliminar a ${first_name} ${last_name} (${email})?`)
        ) {
            deleteEmployeeMutation.mutate(id);
        }
    }

    return (
        <Button
            icon="trash alternate"
            onClick={onClick}
            disabled={deleteEmployeeMutation.isLoading}
        />
    );
}

function WriteEmployeeRow(props: {
    employee: Employee;
    projectRoles: Role[];
    onChangesSaved(): void;
    onClickCancel(): void;
}) {
    const { employee, projectRoles, onClickCancel } = props;
    const initialRoleSet = Immutable.Set(_.map(employee.roles, 'id'));
    const [draftRoleSet, setDraftRoleSet] = useState(initialRoleSet);
    const hasChanges = !draftRoleSet.equals(initialRoleSet);
    const patchEmployeeMutation = usePatchEmployeeMutation();

    async function saveChanges() {
        await patchEmployeeMutation.mutateAsync({
            id: employee.profile.user.id,
            roles: draftRoleSet.toArray(),
        });
        props.onChangesSaved();
    }

    return (
        <tr>
            <td>{employee.profile.user.id}</td>
            <td>{employee.profile.user.first_name}</td>
            <td>{employee.profile.user.last_name}</td>
            <td>
                <EmailLink email={employee.profile.user.email} />
            </td>
            {projectRoles.map(role => (
                <td key={role.id} align="center">
                    <input
                        type="checkbox"
                        checked={draftRoleSet.has(role.id)}
                        onChange={event => {
                            if (event.target.checked) {
                                setDraftRoleSet(prev => prev.add(role.id));
                            } else {
                                setDraftRoleSet(prev => prev.remove(role.id));
                            }
                        }}
                        style={{ transform: 'scale(1.5)' }}
                    />
                </td>
            ))}
            <td>
                <button
                    className="btn-primary w-40"
                    onClick={saveChanges}
                    disabled={!hasChanges || patchEmployeeMutation.isLoading}
                >
                    Guardar cambios
                </button>
                <button className="btn-text text-sm" onClick={onClickCancel}>
                    Cancelar
                </button>
            </td>
        </tr>
    );
}

export function UserManagerScreen() {
    const [open, setOpen] = useState(false);

    return (
        <Screen className="flex flex-row">
            <SideBar />
            <main className="flex-1 bg-neutral p-10">
                <EmployeesTable />
                <div className="bg-neutral w-full sticky bottom-0 pt-2 pb-2">
                    <button
                        className="btn-primary w-48"
                        onClick={() => setOpen(true)}
                    >
                        Registrar usuario
                    </button>
                </div>
                <SignUpModal open={open} onClose={() => setOpen(false)} />
            </main>
        </Screen>
    );
}

function SignUpModal(props: ModalProps) {
    const signUpMutation = useSignUpMutation();

    async function onSubmit(formValues: {
        first_name: string;
        last_name: string;
        email: string;
        password: string;
    }) {
        await signUpMutation.mutateAsync(formValues);
        // alert('Usuario registrado con éxito');
        props.onClose?.(null!, null!);
    }

    return (
        <Modal open={props.open} onClose={props.onClose} className="w-max">
            <Modal.Header>Registrar usuario</Modal.Header>
            <Modal.Content scrolling className="bg-neutral">
                {isEmailAlreadyExistsError(signUpMutation.error) && (
                    <Message negative>
                        Ya existe un usuario con correo{' '}
                        {signUpMutation.error.data.detail.match(emailRegex)}.
                    </Message>
                )}

                <FormPreview
                    submitLabel="Registrar usuario"
                    submittingLabel="Registrando..."
                    onSubmit={onSubmit}
                >
                    <FormContainer className="w-72">
                        <TextField name="first_name" label="Nombre" />
                        <TextField name="last_name" label="Apellido" />
                        <EmailField name="email" label="Correo electrónico" />
                        <PasswordField name="password" label="Contraseña" />
                        <RepeatPasswordField
                            relatedName="password"
                            label="Repetir contraseña"
                        />
                        <SubmitButton />
                    </FormContainer>
                </FormPreview>
            </Modal.Content>
        </Modal>
    );
}

function isEmailAlreadyExistsError(error: unknown): error is FetchError & {
    status: 409;
    data: { detail: string };
} {
    return (
        isFetchError(error) &&
        error.response.status === 409 &&
        _.isString(error.data.detail)
    );
}
