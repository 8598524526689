import { Locale } from './Locale';
import { sharedSpanish } from 'lowcode-shared-code';

export const spanish: Locale = {
    ...sharedSpanish,

    applicationNotFoundHeader: 'Solicitud no encontrada',
    applicationNotFoundExplanation: applicationId =>
        `No pudimos encontrar la solicitud con ID "${applicationId}".`,
    startApplication: 'Iniciar solicitud',
    startingApplication: 'Iniciando solicitud...',
};
