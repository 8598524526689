import {
    Application,
    Await,
    makeRef,
    ShortId,
    useFlowDetailQuery,
} from 'lowcode-shared-code';
import { ApplicationState, CellList } from '../../ui-components/EventsCellList';
import React from 'react';
import { useNavigator } from '../../utils/useNavigator';
import { Button } from 'semantic-ui-react';

export function DashboardTable(props: { applications: Application[] }) {
    const { applications } = props;

    return (
        <table className="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Cliente</th>
                    <th>RUT</th>
                    <th>Flujo</th>
                    <th>Estado</th>
                    <th>Empleado asignado</th>
                    <th>Ver solicitud</th>
                </tr>
            </thead>
            <tbody>
                {applications.map(application => (
                    <DashboardRow
                        key={application.id}
                        application={application}
                    />
                ))}
            </tbody>
        </table>
    );
}

function DashboardRow(props: { application: Application }) {
    const { application } = props;
    const flowDetailQuery = useFlowDetailQuery(makeRef(application.flow));
    const navigator = useNavigator();

    return (
        <tr>
            <td>
                <ShortId id={application.id} />
            </td>
            <td>{(application.application_data as any).name}</td>
            <td>{(application.application_data as any).rut}</td>
            <td>
                <Await query={flowDetailQuery}>
                    {flow => <span>{flow.name}</span>}
                </Await>
            </td>
            <td>
                <Await query={flowDetailQuery}>
                    {flow => (
                        <ApplicationState
                            application={application}
                            flow={flow}
                        />
                    )}
                </Await>
            </td>
            <td>
                <CellList
                    items={application.state.map(s => {
                        const { first_name, last_name } =
                            s.employee.profile.user;
                        return `${first_name} ${last_name}`;
                    })}
                    emptyLabel="Ninguno"
                />
            </td>
            <td>
                <Button
                    icon="external"
                    onClick={() => navigator.goToApplication(application)}
                />
            </td>
        </tr>
    );
}
