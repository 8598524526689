import { useMutation, useQueryClient } from 'react-query';
import {
    Action,
    Application,
    Employee,
    endpoints,
    FormOverview,
    getQueryKey,
    Ref,
    Role,
} from 'lowcode-shared-code';

export function usePostApplicationMutation() {
    const queryClient = useQueryClient();

    return useMutation(endpoints.postApplication.fetch, {
        onSuccess() {
            queryClient
                .invalidateQueries(endpoints.getPaginatedApplications.url)
                .then();
        },
    });
}

export function usePatchFormDataMutation(context: {
    application: Ref<Application>;
    form: Ref<FormOverview>;
}) {
    const queryClient = useQueryClient();

    return useMutation(
        (formData: FormData) =>
            endpoints.patchFormData.fetch(
                context.application.id,
                context.form.id,
                formData
            ),
        {
            onSuccess() {
                queryClient
                    .invalidateQueries(endpoints.getPaginatedApplications.url)
                    .then();
                queryClient
                    .invalidateQueries([
                        endpoints.applicationDetail.url,
                        context.application.id,
                    ])
                    .then();
                queryClient.invalidateQueries(endpoints.getInbox.url).then();
            },
        }
    );
}

export function usePatchEmployeeMutation() {
    const queryClient = useQueryClient();

    return useMutation(
        ({
            id,
            ...body
        }: {
            id: Employee['profile']['user']['id'];
            roles?: Role['id'][];
        }) => endpoints.patchEmployee.fetch(id, body),
        {
            onSuccess() {
                queryClient
                    .invalidateQueries(endpoints.getEmployees.url)
                    .then();
            },
        }
    );
}

export function useDeleteEmployeeMutation() {
    const queryClient = useQueryClient();

    return useMutation(endpoints.deleteEmployee.fetch, {
        onSuccess() {
            queryClient
                .invalidateQueries(getQueryKey(endpoints.getEmployees))
                .then();
        },
    });
}

export function usePostExecutionMutation(context: {
    application: Ref<Application>;
}) {
    const queryClient = useQueryClient();

    return useMutation(
        (action: Ref<Action>) =>
            endpoints.postExecution.fetch({
                application: context.application.id,
                action: action.id,
            }),
        {
            onSuccess() {
                queryClient
                    .invalidateQueries(endpoints.getPaginatedApplications.url)
                    .then();
                queryClient
                    .invalidateQueries([
                        endpoints.applicationDetail.url,
                        context.application.id,
                    ])
                    .then();
                queryClient.invalidateQueries(endpoints.getInbox.url).then();
            },
        }
    );
}

export function useSignUpMutation() {
    const queryClient = useQueryClient();

    return useMutation(endpoints.signUp.fetch, {
        onSuccess() {
            queryClient.invalidateQueries(endpoints.getEmployees.url).then();
        },
    });
}

export function useRequestPasswordResetMutation() {
    return useMutation(endpoints.requestPasswordReset.fetch);
}

export function useRecoverPasswordMutation() {
    return useMutation(endpoints.recoverPassword.fetch);
}

export function useChangePasswordMutation() {
    return useMutation(endpoints.changePassword.fetch);
}
