import React, { PropsWithChildren } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useProject } from '../../common/ProjectProvider';
import {
    Application,
    Await,
    ClassNamesProvider,
    ComponentsProvider,
    endpoints,
    EventSideModal,
    FlowViewer,
    makeRef,
    PdfTemplate,
    Ref,
    UrlParams,
    useApplicationDetailQuery,
    useComponents,
    useEventOverview,
    useFlowDetailQuery,
    useGetPdfTemplatesQuery,
} from 'lowcode-shared-code';
import { InteractivityProvider } from './InteractivityProvider';
import { EventSideModalProvider } from './EventSideModalProvider';
import {
    ApplicationProvider,
    useApplication,
} from '../../utils/applicationContext';
import { FlowProvider, useFlow } from '../../utils/flowContext';
import {
    Button,
    Dropdown,
    DropdownItem,
    Message,
    Popup,
} from 'semantic-ui-react';
import { useLocale } from '../../locales/localeContext';
import { Screen } from '../Screen';
import _ from 'lodash';
import {
    ApplicationState,
    CellList,
    EventsCellList,
} from '../../ui-components/EventsCellList';
import { UserSummary } from './UserSummary';

export function WorkflowScreen() {
    const params = useParams<{
        applicationId?: string;
        eventId?: string;
    }>();

    return (
        <Screen className="bg-gray-200">
            <Navbar />
            <main className="w-full px-8">
                <ApplicationDetailProvider
                    application={makeRef(params.applicationId!)}
                >
                    <ApplicationViewer />
                </ApplicationDetailProvider>
            </main>
        </Screen>
    );
}

function ApplicationViewer() {
    const locale = useLocale();
    const params = useParams<UrlParams>();
    const application = useApplication();
    const flowDetailQuery = useFlowDetailQuery(makeRef(application.flow));

    // noinspection PointlessBooleanExpressionJS
    if (application == null) {
        return (
            <Message negative>
                <Message.Header>
                    {locale.applicationNotFoundHeader}
                </Message.Header>
                <p>
                    {locale.applicationNotFoundExplanation(
                        params.applicationId!
                    )}
                </p>
            </Message>
        );
    }

    return (
        <React.Fragment>
            <ApplicationSummary />
            <Await query={flowDetailQuery}>
                {flow => (
                    <FlowProvider flow={flow}>
                        <ApplicationFlow />
                    </FlowProvider>
                )}
            </Await>
        </React.Fragment>
    );
}

function ApplicationFlow() {
    const params = useParams<{
        applicationId?: string;
        eventId?: string;
    }>();
    const flow = useFlow();

    return (
        <InteractivityProvider>
            <ComponentsProvider components={{ EventBlock }}>
                <ClassNamesProvider
                    classNames={{ GatewayRhombusContainer: 'opacity-50' }}
                >
                    <FlowViewer flow={flow} />
                </ClassNamesProvider>
            </ComponentsProvider>
            <EventSideModalProvider>
                <EventSideModal eventId={params.eventId} />
            </EventSideModalProvider>
        </InteractivityProvider>
    );
}

function EventBlock() {
    const params = useParams<UrlParams>();
    const components = useComponents();
    const application = useApplication();
    const event = useEventOverview();
    const isActiveEvent = _.map(application.state, 'event').includes(event.id);
    const assignedEmployee = application.state.find(
        s => s.event === event.id
    )?.employee;

    return (
        <ClassNamesProvider
            classNames={{
                EventBlockContainer: isActiveEvent
                    ? 'border-2 border-yellow-400'
                    : 'opacity-50',
            }}
        >
            <Popup
                flowing
                trigger={
                    <div>
                        <components.old.EventBlock />
                    </div>
                }
                content={
                    assignedEmployee && (
                        <UserSummary user={assignedEmployee.profile.user} />
                    )
                }
                position="top center"
                disabled={!assignedEmployee || Boolean(params.eventId)}
                hideOnScroll
                hoverable
            />
        </ClassNamesProvider>
    );
}

function ApplicationSummary() {
    const application = useApplication();
    const flowDetailQuery = useFlowDetailQuery(makeRef(application.flow));

    return (
        <React.Fragment>
            <Await query={flowDetailQuery}>
                {flow => <h3>{flow.name}</h3>}
            </Await>
            <ApplicationCard application={application} />
        </React.Fragment>
    );
}

function ApplicationCard(props: { application: Application }) {
    const { application } = props;
    const flowDetailQuery = useFlowDetailQuery(makeRef(application.flow));

    return (
        <Card>
            <Card.Info>
                <Card.Label>Solicitud</Card.Label>
                <Card.Value>
                    <code>{application.id}</code>
                </Card.Value>
            </Card.Info>
            {/*<Card.Info>*/}
            {/*    <Card.Label>Plazo</Card.Label>*/}
            {/*    <Card.Value>60 días</Card.Value>*/}
            {/*</Card.Info>*/}
            <Card.Info>
                <Card.Label>Nombre cliente</Card.Label>
                <Card.Value>
                    {(application.application_data as any).name}
                </Card.Value>
            </Card.Info>
            <Card.Info>
                <Card.Label>RUT cliente</Card.Label>
                <Card.Value>
                    {(application.application_data as any).rut}
                </Card.Value>
            </Card.Info>
            <Card.Info>
                <Card.Label>Estado actual</Card.Label>
                <Card.Value>
                    <Await query={flowDetailQuery}>
                        {flow => (
                            <ApplicationState
                                application={application}
                                flow={flow}
                            />
                        )}
                    </Await>
                </Card.Value>
            </Card.Info>
            <Card.Info>
                <Card.Label>Empleado asignado</Card.Label>
                <Card.Value>
                    <CellList
                        items={application.state.map(s => {
                            const { first_name, last_name } =
                                s.employee.profile.user;
                            return `${first_name} ${last_name}`;
                        })}
                        emptyLabel="Ninguno"
                    />
                </Card.Value>
            </Card.Info>
            <div className="flex-1" />
            <div>
                <PdfDropdown />
            </div>
            {/*<div>*/}
            {/*    <Button basic color="yellow">*/}
            {/*        <span className="text-normal font-semibold">*/}
            {/*            Sistema Documental{' '}*/}
            {/*            <Icon name="chevron right" className="pl-4" />*/}
            {/*        </span>*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </Card>
    );
}

function PdfDropdown() {
    const application = useApplication();
    const getPdfTemplatesQuery = useGetPdfTemplatesQuery();

    async function downloadPdf(pdfTemplate: PdfTemplate) {
        const request = await endpoints.downloadPdf.makeRequestObject(
            pdfTemplate.id,
            application.id
        );
        window.open(request.url, '_blank');
    }

    return (
        <Dropdown
            floating
            trigger={
                <Button basic color="yellow">
                    <span className="text-normal font-semibold">
                        Descargar PDF
                    </span>
                </Button>
            }
            icon={null}
            loading={getPdfTemplatesQuery.isLoading}
            error={getPdfTemplatesQuery.isError}
        >
            <Dropdown.Menu className="w-full">
                {getPdfTemplatesQuery.data?.map(pdfTemplate => (
                    <DropdownItem
                        key={pdfTemplate.id}
                        text={pdfTemplate.name}
                        onClick={() => downloadPdf(pdfTemplate)}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

function Card(props: PropsWithChildren<{}>) {
    return (
        <div className="card flex flex-row flex-wrap space-x-12 items-center">
            {props.children}
        </div>
    );
}

Card.Info = function CardInfo(props: PropsWithChildren<{}>) {
    return <div className="px-2 py-2">{props.children}</div>;
};

Card.Label = function CardTitle(props: PropsWithChildren<{}>) {
    return <h4 className="text-gray font-semibold">{props.children}</h4>;
};

Card.Value = function CardValue(props: PropsWithChildren<{}>) {
    return <span className="font-semibold">{props.children}</span>;
};

function ApplicationDetailProvider(
    props: PropsWithChildren<{
        application: Ref<Application>;
    }>
) {
    const applicationDetailQuery = useApplicationDetailQuery(props.application);

    return (
        <Await query={applicationDetailQuery}>
            {application => (
                <ApplicationProvider application={application}>
                    {props.children}
                </ApplicationProvider>
            )}
        </Await>
    );
}

function Navbar() {
    const project = useProject();

    return (
        <nav className="flex flex-row items-center pb-3">
            <div className="px-2 py-1">
                <img src={project.logo} alt="" className="h-12 mb-0" />
            </div>
            <div>
                <Link
                    to="/dashboard"
                    className="font-gray font-semibold px-6 py-3"
                >
                    Inicio
                </Link>
            </div>
        </nav>
    );
}
